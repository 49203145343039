<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :range="true"
            label="TBM기간"
            defaultStart="-7d"
            defaultEnd="0d"
            name="period"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
      <c-table
        ref="table"
        title="TBM 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :columnSetting="false"
        :isFullScreen="false"
        :usePaging="false"
        :expandAll="true"
        @linkClick="linkClick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="등록" icon="add" @btnClicked="linkClick" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
      </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'tbm',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            // style: 'width:100px',
            fix: true,
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmNo',
            field: 'tbmNo',
            label: 'TBM번호',
            // style: 'width:120px',
            fix: true,
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            align: 'left',
            // style: 'width:200px',
            fix: true,
            type: 'link',
            sortable: true,
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            label: '작업허가서',
            // style: 'width:120px',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          // {
          //   name: 'tbmCompleteFlagName',
          //   field: 'tbmCompleteFlagName',
          //   label: '작성상태',
          //   style: 'width:80px',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'tbmLocation',
            field: 'tbmLocation',
            label: '장소',
            align: 'center',
            sortable: true,
            // style: 'width:80px',
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            label: '작업일',
            // style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmDate',
            field: 'tbmDate',
            label: 'TBM일',
            // style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workTime',
            field: 'workTime',
            label: 'TBM시간',
            // style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'processName',
          //   field: 'processName',
          //   // style: 'width:120px',
          //   label: '공정',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'vendorName',
            field: 'vendorName',
            // style: 'width:120px',
            label: '업체',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmLeaderName',
            field: 'tbmLeaderName',
            label: 'TBM리더',
            // style: 'width:120px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      period: [],
      searchParam: {
        startYmd: '',
        endYmd: '',
        plantCd: '',
        processCd: '',
        sopWorkPermitId: '',
        tbmCompleteFlag: null,
        tbmLeaderId: ''
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      statusItems: [
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '완료' },
      ],
      editable: true,
      listUrl: '',
      mobileUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        // isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      latestTbmId: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.tbm.list.url;
      this.mobileUrl = '/api/sai/tbm/mobile/' + this.searchParam.plantCd +'/'+ this.$store.getters.user.userId
      this.$http.url = this.mobileUrl
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.latestTbmId = _result.data.tbmId
      })
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data.map(item=>{
          return {
            ...item,
            workSummary: item.sopWorkPermitId ? item.permitNo + " | " + item.workSummary : ''
          }
        })
      },);
    },
    linkClick(result, col) {
      if (result && col.name === 'workSummary') {
        this.popupOptions.target = () => import(`${"@/pages/sop/swp/safeWorkPermitDetail.vue"}`);
        this.popupOptions.title = '작업허가서 상세';
        this.popupOptions.param = {
          sopWorkPermitId: result.sopWorkPermitId,
          permitTypeCd: result.permitTypeCd
        };
      } else {
        this.popupOptions.target = () => import(`${"./tbmDetail.vue"}`);
        this.popupOptions.title = 'TBM 상세';
        this.popupOptions.param = {
          tbmId: result ? result.tbmId : '',
        };
      }
      this.popupOptions.isFull = true;
      // this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
